<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="editUser">
      <b-form @submit.prevent="submitUser">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <validation-provider name="كلمة المرور القديمة" :rules="{ required: true }" #default="{ errors }">
              <b-form-group label="كلمة المرور القديمة" label-for="account-old-password">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="كلمة المرور القديمة"
                  />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
                  </b-input-group-append>
                  <b-form-invalid-feedback id="password-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <validation-provider name="password" :rules="{ required: true }" #default="{ errors }">
              <b-form-group label-for="account-new-password" :label="$t('auth.password')">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    :placeholder="$t('auth.password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                  </b-input-group-append>
                </b-input-group>

                <b-form-invalid-feedback id="password-feedback">{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              :name="$t('auth.confirm_password')"
              rules="confirmed:password|required:true"
              #default="{ errors }"
            >
              <b-form-group label-for="account-retype-new-password" :label="$t('auth.confirm_password')">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    :placeholder="$t('auth.confirm_password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer" @click="togglePasswordRetype" />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback id="confirmation-feedback">{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              :disabled="submitProcessing"
              type="submit"
            >
              {{ $t("crud.update") }}
            </b-button>
            <span v-if="!submitProcessing"> <i class="fa fa-spin"></i> </span>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { LogoutUser } from "@/auth/utils";
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend
  },
  directives: {
    Ripple
  },
  data() {
    return {
      submitProcessing: false,
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
      errorsres: {}
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password" ? "EyeIcon" : "EyeOffIcon";
    }
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    submitUser() {
      self.errorsres = {};
      this.$refs.editUser.validate().then(success => {
        if (!success) {
          this.$makeToast("danger", this.$t("Please_fill_the_form_correctly"), this.$t("Failed"));
        } else {
          this.updateUser();
        }
      });
    },

    //---------------------------------- Update Settings ----------------\\
    updateUser() {
      var self = this;
      self.errorsres = {};
      this.submitProcessing = true;
      var user = {
        old_password: this.passwordValueOld,
        password: this.newPasswordValue,
        password_confirmation: this.RetypePassword
      };
      axios
        .post("change-password", user)
        .then(response => {
          self.submitProcessing = false;
          let msg = this.$responseSolve(response, false);

          this.$makeToast("success", msg, this.$t("Success"));
          // this.formRest();
          LogoutUser();

          this.$router.push({ name: "auth-login" });
        })
        .catch(error => {
          this.submitProcessing = false;
          let msg = this.$responseSolve(error, true, "danger");
          self.errorsres = this.$errorsres(error);

          this.$makeToast("danger", msg, this.$t("Failed"));
        });
    }
  }
};
</script>
