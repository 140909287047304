<template>
  <b-card>
    <!-- media -->
    <!-- <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img ref="previewEl" rounded :src="optionsLocal.avatar" height="80" />
        </b-link> 
      </b-media-aside>

      <b-media-body class="mt-75 ml-75"> 
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        /> 
 
        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm" class="mb-75 mr-75">
          Reset
        </b-button> 
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media> -->
    <!--/ media -->
    <validation-observer ref="editBusiness">
      <!-- form -->
      <b-form @submit.prevent="submitBusiness" class="mt-2" v-if="form">
        <b-row>
          <!-- <b-col sm="6">
          <b-form-group label="Username" label-for="account-username">
            <b-form-input v-model="form.username" placeholder="Username" name="username" />
          </b-form-group>
        </b-col> -->

          <b-col md="6">
            <validation-provider :name="$t('lang.supplier_business_name')" :rules="{ required: false }">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-cols-sm="4"
                :label="$t('lang.supplier_business_name') + ' ' + '*'"
              >
                <b-form-input
                  v-model="form.supplier_business_name"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.supplier_business_name')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('lang.name')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.name') + ' ' + '*'">
                <b-form-input
                  v-model="form.name"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.name')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider :name="$t('lang.first_name')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.first_name') + ' ' + '*'">
                <b-form-input
                  v-model="form.first_name"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.first_name')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('lang.middle_name')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.middle_name') + ' ' + '*'">
                <b-form-input
                  v-model="form.middle_name"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.middle_name')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider :name="$t('lang.last_name')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.last_name') + ' ' + '*'">
                <b-form-input
                  v-model="form.last_name"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.last_name')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('lang.mobile')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.mobile') + ' ' + '*'">
                <b-form-input
                  v-model="form.mobile"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.mobile')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- تكرار القالب لبقية الحقول المرسلة -->
          <!-- استمر في تكرار القالب لبقية الحقول المرسلة مع تغيير أسماء الحقول والترجمة وفقًا للبيانات المرسلة -->

          <!-- country -->
          <!-- <b-col md="6">
          <b-form-group label-for="countryList" label="Country">
            <v-select
              id="countryList"
              v-model="localOptions.country"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="countryOption"
            />
          </b-form-group>
        </b-col> -->
          <!--/ Country -->

          <b-col md="6">
            <validation-provider :name="$t('lang.email')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.email') + ' ' + '*'">
                <b-form-input
                  v-model="form.email"
                  type="email"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.email')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('lang.mobile')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.mobile') + ' ' + '*'">
                <b-form-input
                  v-model="form.mobile"
                  type="tel"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.mobile')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- تكرار القالب لبقية الحقول المرسلة -->
            <!-- استمر في تكرار القالب لبقية الحقول المرسلة مع تغيير أسماء الحقول والترجمة وفقًا للبيانات المرسلة -->

            <validation-provider :name="$t('lang.prefix')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.prefix') + ' ' + '*'">
                <b-form-input
                  v-model="form.prefix"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.prefix')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('lang.country')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.country') + ' ' + '*'">
                <b-form-input
                  v-model="form.country"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.country')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('lang.email')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.email') + ' ' + '*'">
                <b-form-input
                  v-model="form.email"
                  type="email"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.email')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- <validation-provider :name="$t('lang.customer_group_id')" :rules="{ required: false }">
  <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.customer_group_id') + ' ' + '*'">
    <b-form-input
      v-model="form.customer_group_id"
      type="text"
      :class="{ 'is-invalid': !!errors.length }"
      :state="errors[0] ? false : valid ? true : null"
      :placeholder="$t('lang.customer_group_id')"
    />
    <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
  </b-form-group>
</validation-provider> -->

          <!-- تكرار القالب لبقية الحقول المرسلة -->
          <!-- استمر في تكرار القالب لبقية الحقول المرسلة مع تغيير أسماء الحقول والترجمة وفقًا للبيانات المرسلة -->

          <!-- <validation-provider :name="$t('lang.phone')" :rules="{ required: false }">
  <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.phone') + ' ' + '*'">
    <b-form-input
      v-model="form.phone"
      type="text"
      :class="{ 'is-invalid': !!errors.length }"
      :state="errors[0] ? false : valid ? true : null"
      :placeholder="$t('lang.phone')"
    />
    <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
  </b-form-group>
</validation-provider> -->

          <b-col md="6">
            <validation-provider :name="$t('lang.address')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.address') + ' ' + '*'">
                <b-form-input
                  v-model="form.address"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.address')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('lang.city')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.city') + ' ' + '*'">
                <b-form-input
                  v-model="form.city"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.city')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('lang.shipping_address')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.shipping_address') + ' ' + '*'">
                <b-form-input
                  v-model="form.shipping_address"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.shipping_address')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('lang.shipping_custom_field_details')" :rules="{ required: false }">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-cols-sm="4"
                :label="$t('lang.shipping_custom_field_details') + ' ' + '*'"
              >
                <b-form-input
                  v-model="form.shipping_custom_field_details"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.shipping_custom_field_details')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('lang.state')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.state') + ' ' + '*'">
                <b-form-input
                  v-model="form.state"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.state')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider :name="$t('lang.tax_number')" :rules="{ required: false }">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" :label="$t('lang.tax_number') + ' ' + '*'">
                <b-form-input
                  v-model="form.tax_number"
                  type="text"
                  :class="{ 'is-invalid': !!errors.length }"
                  :state="errors[0] ? false : valid ? true : null"
                  :placeholder="$t('lang.tax_number')"
                />
                <b-form-invalid-feedback> {{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1">
              حفظ</b-button
            >
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              استاعدة
            </b-button> -->
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink
  },
  directives: {
    Ripple
  },
  props: {
    // form: {
    //   type: Object,
    //   default: () => {}
    // }
  },
  data() {
    return {
      isLoading: false,
      profileFile: null,
      user: null,
      form: null
    };
  },
  created() {
    this.getBusiness();
    // this.business = this.$store.state.auth.user;
  },
  methods: {
    resetForm() {},
    getBusiness() {
      axios
        .get("profile")
        .then(response => {
          var contact = response.data.contact;
          this.form = response.data;

          delete this.form.contact;
          this.form = {
            ...this.form,
            ...contact
          };

          this.isLoading = false;
        })
        .catch(response => {
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    },
    // ------------- Submit Validation Setting
    submitBusiness() {
      self.errorsres = {};
      this.$refs.editBusiness.validate().then(success => {
        if (!success) {
          this.$makeToast("danger", this.$t("Please_fill_the_form_correctly"), this.$t("Failed"));
        } else {
          this.updateBusiness();
        }
      });
    },
    // ---------------------------------- Update Settings ----------------\\
    updateBusiness() {
      const self = this;
      self.errorsres = {};
      this.submitProcessing = true;
      axios
        .post(`updateProfile`, self.form)
        .then(response => {
          self.submitProcessing = false;
          const msg = this.$responseSolve(response, false);
          this.$makeToast("success", msg, this.$t("Success"));

          //    this.$router.push({ name: "businesses.index" });
        })
        .catch(error => {
          this.submitProcessing = false;
          const msg = this.$responseSolve(error, true, "danger");
          self.errorsres = this.$errorsres(error);
          this.$makeToast("danger", msg, this.$t("Failed"));
        });
    }
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer
    };
  }
};
</script>
